import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AppBar, CssBaseline, makeStyles, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import bg from '../../assets/images/bg.jpg';

import { drawerWidth } from '../../store/constant';
import Header from './Header';
import Sidebar from './Sidebar';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    toolbar: theme.mixins.toolbar,
    content: {
        width: '100%',
        minHeight: '100vh',
        flexGrow: 1,
        /*padding: theme.spacing(3),*/
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    main: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    header: {
        zIndex: 1201,
    },
}));

const MainLayout = ({ children }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    let history = useHistory();
    const [verification, setVerification] = useState(true);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    React.useEffect(() => {
        setDrawerOpen(matchUpMd);
    }, [matchUpMd]);

    const blankData = <div
        style={{ minHeight: '100vh', background: `url(${bg}) center center no-repeat`, backgroundSize: 'cover' }} />;

    const userVerification = () => {
        const token = localStorage.getItem('token');
        if (token === null) {
            setVerification(false);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Verifikasi User Gagal, Silakan Login Kembali`,
            }).then(() => {
                history.push('/');
                setVerification(true);
            });
        }
    };
    useEffect(() => {
        userVerification();
    }, []);

    return (
        <>{verification ?
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position='fixed' className={classes.header}>
                    <Toolbar style={{ background: '#0e1111' }}>
                        <Header drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
                    </Toolbar>
                </AppBar>
                <Sidebar drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
                <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
                    <div className={classes.toolbar} />
                    <div className={classes.main}>{children}</div>
                </main>
            </div>
            : blankData}
        </>
    );
};

export default MainLayout;
