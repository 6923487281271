import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Loader from './component/Loader/Loader';
import NavMotion from './layout/NavMotion';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';

const DashboardDefault = lazy(() => import('./views/Dashboard/Default'));
const Services = lazy(() => import('./views/Services'));
const Projects = lazy(() => import('./views/Projects'));
const Gallery = lazy(() => import('./views/Gallery'));
const Contacts = lazy(() => import('./views/Contacts'));
const Login = lazy(() => import('./views/Login'));
const Routes = () => {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Redirect exact from='/' to='/Login' />
                    <Route path='/Login' component={Login} />
                    <Route path={[]}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion />
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route
                        path={[
                            '/Login',
                            '/dashboard',
                            '/services',
                            '/contacts',
                            '/projects',
                            '/gallery',
                        ]}
                    >
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <Route path='/dashboard' component={DashboardDefault} />
                                    <Route path='/services' component={Services} />
                                    <Route path='/projects' component={Projects} />
                                    <Route path='/gallery' component={Gallery} />
                                    <Route path='/contacts' component={Contacts} />
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
