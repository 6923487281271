import React from 'react';
import { Box, Grid, Hidden, IconButton, makeStyles } from '@material-ui/core';

import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';

import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from '../../../store/constant';
import Logo from '../../../../src/assets/images/logo.png';


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1.25),
        color: '#d6b162',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
}));

const Header = (props) => {
    const { drawerToggle } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box width={drawerWidth}>
                <Grid container justify='space-between' alignItems='center'>
                    <Hidden smDown>
                        <Grid item>
                            <Box mt={0.5}>
                                <img src={Logo} width={150}
                                     alt='Logo' />
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <IconButton
                            edge='start'
                            className={classes.menuButton}
                            color='#d6b162'
                            aria-label='open drawer'
                            onClick={drawerToggle}
                        >
                            <MenuTwoToneIcon className={classes.menuIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <div className={classes.grow} />

            <SearchSection theme='light' />
            <NotificationSection />
            <ProfileSection />
        </React.Fragment>
    );
};

export default Header;
